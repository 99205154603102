import { connect } from 'react-redux';
import MainApp from '../components/MainApp.jsx';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
  showFullScreenLoader: state.reducer.loaderState.init > 0,
});

const mapDispatchToProps = {
};

const MainAppView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainApp);

export default MainAppView;
