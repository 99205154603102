import {
  AUTH_USER_LOGOUT,
  GET_CURRENT_USER_SUCCESFUL,
  AUTH_USER_ERROR,
  REQUEST_AUTHENTICATE_USER,
  AUTH_USER_SUCCESSFUL,
  UPDATE_CURRENT_USER_SUCCESFUL,
} from '../constants/ActionTypes.js';

const initialState = {
  user: {},
  authenticated: false,
  disabled: false,
};

const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_AUTHENTICATE_USER:
      // console.log('Authenticating...');
      return state;
    case AUTH_USER_ERROR:
      // console.log('Auth error with payload:');
      // console.log(action.payload);
      throw action.payload;
    // return state;
    case AUTH_USER_SUCCESSFUL: {
      const newState = Object.assign({}, state);
      newState.authToken = action.payload;
      newState.authenticated = true;
      return newState;
    }
    case GET_CURRENT_USER_SUCCESFUL: {
      const { user } = action.payload;
      const newStateWithUser = Object.assign({}, state, {
        user,
      });
      return newStateWithUser;
    }
    case UPDATE_CURRENT_USER_SUCCESFUL: {
      const { user } = action.payload;
      const newStateWithUser = Object.assign({}, state, {
        user,
      });
      return newStateWithUser;
    }
    case AUTH_USER_LOGOUT: {
      const clearState = Object.assign({}, initialState);
      clearState.authenticated = false;
      // console.log('Logging out...');
      return clearState;
    }
    default:
      return state;
  }
};
export default currentUser;
