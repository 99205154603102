import React from 'react';
import { FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';
import * as actionTypes from '../constants/ActionTypes.js';
import {
  setCookie,
  getCookie,
  checkCookie,
  deleteCookie,
} from '../utils/cookieHandlers.js';
import { conn } from './index.js';
import { increaseLoaderCounter, decreaseLoaderCounter } from './uiState.js';
import { updatePermissions } from './userPermissions.js';
import { fetchUserLocations } from './userLocations.js';
import { SCHEDULE_SPECTATOR_VIEW } from '../constants/Permissions.js';

const COOKIE_EXPIRY_DAYS = 7;

export const authUserSuccesful = (tokenData) => ({
  type: actionTypes.AUTH_USER_SUCCESSFUL,
  payload: tokenData,
});

export const setAuthToken = (token, requestType) => {
  conn.setAuthToken(token, requestType);
  return {
    type: actionTypes.SET_AUTH_TOKEN_SUCCESS,
  };
};

export const authLogout = () => ({
  type: actionTypes.AUTH_USER_LOGOUT,
  payload: 'logoutClick',
});

export const authUserErrorMsg = (requestPayload, notification) => ({
  type: actionTypes.AUTH_USER_ERROR,
  payload: requestPayload,
  notification,
});

export const storeAuthTokenLocally = (token) => {
  setCookie('kadroToken', token, COOKIE_EXPIRY_DAYS);
  return {
    type: actionTypes.STORE_AUTH_TOKEN_SUCCESS,
  };
};

export const getCurrentUserSuccesful = (data) => ({
  type: actionTypes.GET_CURRENT_USER_SUCCESFUL,
  payload: data,
});

export const getCurrentCompanySuccesful = (data) => ({
  type: actionTypes.GET_CURRENT_COMPANY_SUCCESFUL,
  payload: data,
});

export const getCurrentCompanyError = (err) => ({
  type: actionTypes.GET_CURRENT_COMPANY_ERROR,
  payload: err,
});

export const authUserError = (requestPayload) => (dispatch) => {
  const description = (
    <FormattedMessage
      id="login.incorrectPasswordOrName"
      defaultMessage="Podano błędny adres email lub hasło"
    />
  );
  const title = (
    <FormattedMessage
      id="login.logInError"
      defaultMessage="Błąd podczas logowania"
    />
  );
  dispatch(
    authUserErrorMsg(requestPayload, {
      type: 'error',
      title: '',
      options: {
        component: () => (
          <div>
            <div className="rrt-title">{title}</div>
            {description}
          </div>
        ),
      },
    }),
  );
};

const logoutError = (e) => {
  console.error('[logout] Failed to logout => ', e);
  return {
    type: actionTypes.AUTH_USER_LOGOUT_ERROR,
    notification: {
      title: 'Ups!',
      description: 'Nie udało się wylogować',
      type: 'error',
    },
  };
};

const handleLogout = (dispatch) => {
  dispatch(authLogout());
  deleteCookie('kadroToken');
  conn.setAuthToken(undefined);
  if (process.env.NODE_ENV !== 'development' && window.heap) {
    window.heap.resetIdentity();
  }
  browserHistory.push('/');
};

export const logoutUser = () => (dispatch, getState) => {
  conn
    .revokeToken()
    .then(() => {
      handleLogout(dispatch, getState);
    })
    .catch((e) => {
      if (
        e.response &&
        (e.response.status === 401 || e.response.status === 403)
      ) {
        handleLogout(dispatch, getState);
        return;
      }
      dispatch(logoutError(e));
    });
};

export const getCurrentCompany = (accountType, requestType) => (dispatch) => {
  conn
    .getCurrentCompany(requestType)
    .then((response) => {
      conn.setCompanyId(response.data.company.id, requestType);
      dispatch(getCurrentCompanySuccesful(response.data));
    })
    .catch((err) => {
      const isPasswordExpired =
        err.response.status === 403 &&
        err.response.data.message.includes('Your password has expired');
      if (isPasswordExpired) {
        browserHistory.push('/password/expired');
      } else {
        dispatch(logoutUser());
      }
      dispatch(getCurrentCompanyError(err));
      throw err;
    });
  return {
    type: actionTypes.REQUEST_CURRENT_COMPANY,
  };
};

export const getCurrentUser = (requestType) => (dispatch) =>
  new Promise((resolve) => {
    conn.getCurrentUserInfo(requestType).then((response) => {
      dispatch(getCurrentUserSuccesful(response.data));
      resolve(response.data);
    });
  });

export const getInitData = () => (dispatch) => {
  dispatch(getCurrentUser('init')).then((userData) => {
    dispatch(getCurrentCompany(userData.user.role, 'init'));
    dispatch(fetchUserLocations());
  });
};

export const requestAuthenticateUserFromCode = (code) => (dispatch) => {
  // increase init counter to enable fullscreenloader
  dispatch(increaseLoaderCounter('init'));
  conn
    .getAuthToken(code, 'init')
    .then((response) => {
      const token = response.data.auth_token;
      dispatch(setAuthToken(`Bearer ${token}`, 'init'));
      dispatch(getInitData());
      dispatch(authUserSuccesful(token));
    })
    .catch((err) => {
      dispatch(decreaseLoaderCounter('init'));
      dispatch(authUserError({ code, err }));
    });
  return {
    type: actionTypes.REQUEST_AUTHENTICATE_USER,
    payload: { code },
  };
};

export const requestAuthenticateUser = (email, pass, rememberMe) => (
  dispatch,
) => {
  // increase init counter to enable fullscreenloader
  dispatch(increaseLoaderCounter('init'));
  conn
    .authenticateClient(email, pass, 'init')
    .then((response) => {
      const token = response.data.auth_token;
      dispatch(setAuthToken(token, 'init'));
      dispatch(getInitData());
      dispatch(updatePermissions({ initialized: true }, 'init'))
        .then((res) => {
          if (!res.permissions.includes(SCHEDULE_SPECTATOR_VIEW)) {
            throw new Error('Access denied');
          }
          dispatch(authUserSuccesful(res.data));
        })
        .catch((err) => {
          console.error(err);
        });
      if (rememberMe) {
        dispatch(storeAuthTokenLocally(token));
      }
      dispatch(decreaseLoaderCounter('init'));
    })
    .catch((err) => {
      dispatch(decreaseLoaderCounter('init'));
      dispatch(authUserError({ email, pass, rememberMe, err }));
    });
  return {
    type: actionTypes.REQUEST_AUTHENTICATE_USER,
    payload: { email, pass, rememberMe },
  };
};

export const checkCookiesForTokens = () => (dispatch) => {
  if (checkCookie() != null) {
    const loadedToken = getCookie('kadroToken');
    dispatch(increaseLoaderCounter('init'));
    dispatch(setAuthToken(loadedToken, 'init'));
    dispatch(getInitData());
    dispatch(
      authUserSuccesful({
        auth_token: loadedToken,
        note: 'From local storage',
      }),
    );
    dispatch(decreaseLoaderCounter('init'));
  } else {
    return {
      type: actionTypes.NO_TOKEN_STORED,
    };
  }
  return undefined;
};
