import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const MDCheckbox = props => {
  const className = classNames('kmd-checkbox', props.className, {
    'kmd-checkbox--error': props.errorMessage,
    'kmd-checkbox--disabled': props.disabled,
  });

  return (
    <div className={className}>
      <label className="kmd-checkbox__label">
        <input
          type="checkbox"
          value={props.value}
          onChange={props.onChange}
          style={props.style}
          className="kmd-checkbox__input"
          id={props.id}
          name={props.id}
          defaultValue={props.defaultValue}
        />
        <span className="kmd-checkbox__text">{props.text}</span>
      </label>
      <div className="kmd-checkbox__error">{props.errorMessage}</div>
    </div>
  );
};

MDCheckbox.defaultProps = {
  style: {},
  id: '',
  value: '',
};

MDCheckbox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  style: PropTypes.shape({}),
  id: PropTypes.string,
  text: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MDCheckbox;
