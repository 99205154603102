import React from 'react'
import PropTypes from 'prop-types'
import {defineMessages} from 'react-intl';
import {Header, Rows, Grid} from '../common/Grid/Grid.jsx'
import {getHeaders, getCells} from './BasicScheduleHelpers.js'
import {dateModes} from '../../constants/dateModes.js'
import EmptyState from '../EmptyState/EmptyState.jsx'

const messages = defineMessages({
  emptyStateTitle: {
    id: 'basicSchedule.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateMessage: {
    id: 'basicSchedule.emptyStateMessage',
    defaultMessage: 'Wybierz lokalizację z listy w górnej części ekranu, a następnie kliknij przycisk Wczytaj.',
  },
})

const BasicScheduleTable = ({shifts, dateMode}, context) => {
  const numberOfDays = dateModes.find(mode => mode.type === dateMode).length
  const headers = getHeaders(numberOfDays)
  const cells = getCells(shifts, numberOfDays)
  const containerWidth = numberOfDays > 7 ? 120 * numberOfDays : 945;

  if (shifts.length === 0) {
    return (
      <EmptyState 
        imgSrc="/img/scheduleCTA.png"
        name="basicScheduleCTA"
        emptyStateHeader={context.intl.formatMessage(messages.emptyStateTitle)}
        emptyStateText={context.intl.formatMessage(messages.emptyStateMessage)}
      />
    )
  }

  return (
    <Grid
      width={containerWidth}
      columnStyle={{ width: '17vw', minWidth: '270px', maxWidth: '300px' }}
      className="kadroGrid k-basicScheduleTable"
    >
      <Header>
        <thead>
          <tr>
            {headers}
          </tr>
        </thead>
      </Header>
      <Rows>
        <tr>
          {cells}
        </tr>
      </Rows>
    </Grid>
  )
}

BasicScheduleTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
}

BasicScheduleTable.propTypes = {
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
  dateMode: PropTypes.string,
}

export default BasicScheduleTable
