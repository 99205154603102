import React from 'react';
import PropTypes from 'prop-types';

const PermissionWrapper = (props) => (
  <div>
    {props.userPermissions.isEmployee
      ? React.createElement(
          props.route.employee,
          { location: props.location },
          props.children,
        )
      : React.createElement(
          props.route.manager,
          { location: props.location },
          props.children,
        )}
  </div>
);

PermissionWrapper.propTypes = {
  userPermissions: PropTypes.shape({
    isEmployee: PropTypes.bool,
  }),
  route: PropTypes.shape({
    employee: PropTypes.func,
    manager: PropTypes.func,
  }),
  location: PropTypes.shape({}),
  children: PropTypes.node,
};

export default PermissionWrapper;
