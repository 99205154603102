import * as AT from '../constants/ActionTypes.js';

const initialState = {
  html: `<div class="k-unauthenticatedShowcase__title">GRAFIK PRACY</div>
    <div class="k-unauthenticatedShowcase__headline">Śledzenie zmian i statystyki</div>
    <div class="k-unauthenticatedShowcase__intro">
      <span>
        Zyskaj wgląd w prognozę kosztu i czasu pracy personelu aktualizowaną na bieżąco podczas planowania grafiku pracy
      </span>
    </div>
    <div class="k-unauthenticatedShowcase__mainImage">
      <img src="/img/misc/schedule-graph-promo.png" alt="unauthenticatedShowcaseMainImage" />
    </div>`,
};

const promoContents = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_PROMO_CONTENT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default promoContents;
