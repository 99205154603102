/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
import KadroBaseConnections from './BaseConnections.js';

class KadroConnections extends KadroBaseConnections {
  constructor(connections, authentication) {
    super(connections, authentication);
    this.companyID = undefined;
    // TODO: Temporary, moving it outside to connection handlers.
    // this.stagingapi = 'https://stagingapi.kadromierz.pl';
    // this.workerAddress = 'https://stagingapi.kadromierz.pl/workers';
  }
  setCompanyId(companyID) {
    this.companyID = companyID;
  }
  getIp() {
    super.getJSON('https://wtfismyip.com/json').then(data => {
      console.log(data);
    });
  }

  authenticateClient(email, password, requestType) {
    return super.postJSON(
      `${this.base_url}/security/authentication`,
      {
        email,
        password,
      },
      requestType,
    );
    // .then(data => {
    //     // TODO: Store the token inside the object
    //     console.log(data);
    // });
  }

  getAuthToken(code, requestType) {
    return super.postJSON(`${this.base_url}/token-generator/access-token`, { code }, requestType);
  }

  revokeToken() {
    return super.postJSON(`${this.base_url}/security/auth-token/revoke`, {});
  }

  // User

  getCurrentUserInfo(requestType) {
    // TODO: Store all the user info inside the app
    // {"user":{"first_name":"Oskar","last_name":"Paolini","email":"oskar.paolini@gmail.com","phone":null,"invited":false,"role":"owner","avatar":{"small":".png","medium":".png","large":".png"}}}
    return super.getJSON(`${this.base_url}/users/current`, requestType);
  }

  changeCurrentUserInfo(user_object, requestType) {
    // TODO: Store all the user info inside the app
    // {"user":{"first_name":"Oskar","last_name":"Paolini","email":"oskar.paolini@gmail.com","phone":null,"invited":false,"role":"owner","avatar":{"small":".png","medium":".png","large":".png"}}}
    return super.putJSON(`${this.base_url}/users/current`, user_object, requestType);
  }

  // Permissions and Payments
  getUserStatus(requestType) {
    return super.postJSON(`${this.workerAddress}/getUserStatus`, requestType);
  }

  // Company

  getCurrentCompany(requestType) {
    // TODO: Store all the company info inside the app
    // {"company":{"id":"34","name":"Paolini","domain":"paolini","country":null,"address":null,"city":null,"logo_url":{"small":".png","medium":".png","large":".png"}}}
    return super.getJSON(`${this.base_url}/users/current/company`, requestType);
  }

  changeCurrentCompany(company_object, requestType) {
    // TODO: Store all the user info inside the app
    // {"id":"34","name":"Paolini","domain":"paolini","country":"Polska","address":null,"city":null,"logo_url":{"small":".png","medium":".png","large":".png"}}
    return super.putJSON(`${this.base_url}/users/current/company`, company_object, requestType);
  }

  fetchUserLocations(requestType = 'init') {
    return super.getJSON(`${this.base_url}/schedule-spectator/company/location-names`, requestType)
  }

  fetchScheduleForLocation(locationId, numberOfDays) {
    return super.
      getJSON(`${this.base_url}/schedule-spectator/locations/${locationId}/simple-schedule?offset=${numberOfDays}`)
  }
}

export default KadroConnections;
