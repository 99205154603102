import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

const Rows = props => {
  const topFillRows = [];
  const bottomFillRows = [];
  const emptyTDs = [];
  for (let i = 0; i < props.columnsNo; i++) {
    emptyTDs.push(<td key={i} />);
  }
  const h = 54;
  let c = 0;
  for (let i = props.topFill; i >= h; i -= h) {
    topFillRows.push(c);
    c++;
  }
  const lastTop = props.topFill - c * h;
  c = 0;
  for (let i = props.bottomFill; i >= h; i -= h) {
    bottomFillRows.push(c);
    c++;
  }
  const lastBottom = props.bottomFill - c * h;
  const colgroup = props.columnGroup || null;

  const disabledEditOverlayClassnames = classnames('kadroGrid__disabledEditOverlay', {
    'kadroGrid__disabledEditOverlay--full': props.columnsNo === 1,
    'kadroGrid__disabledEditOverlay--small': props.children.length < 7,
  });

  return (
    <div ref={props.getWrapperRef}>
      <div
        className="kadroGrid__rows"
        style={{
          maxHeight: `${props.height}px`,
          borderLeft: props.fixedColumn ? 'none' : '1px solid #ddd',
          position: 'relative',
        }}
        ref={props.getContainerRef}
      >
        <table
          className={`${props.className || ''}`}
          style={{ width: '100%', minWidth: `${props.width}px` }}
          ref={props.getTableRef}
        >
          {colgroup}
          <thead>
            <tr key={-1} className="row--loading" style={{ height: `${lastTop}px` }} />
            {topFillRows.map(row => (
              <tr key={row} className="row--loading" style={{ height: '54px' }}>
                {emptyTDs}
              </tr>
            ))}
          </thead>
          <tbody ref={props.getTBodyRef}>
            {React.Children.map(
              props.children.constructor === Array ? props.children.slice(props.start, props.end) : props.children,
              element => element
            )}
          </tbody>
          <tbody>
            {bottomFillRows.map(row => (
              <tr key={row} className="row--loading" style={{ height: '54px' }}>
                {emptyTDs}
              </tr>
            ))}
            <tr key={-1} className="row--loading" style={{ height: `${lastBottom}px` }} />
            {!props.showEmptyState && (
              <tr>
                <div className={disabledEditOverlayClassnames} style={{ ...props.disabledEditOverlayStyle }}>
                  <div className="kadroGrid__disabledEditContent">
                    <i className="k-icon k-icon-lock_circle_close" />
                    <FormattedMessage id="kadroGrid.disabledEdit.blockedMessage" defaultMessage="Edycja zablokowana" />
                  </div>
                </div>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="kadroGrid__rows kadroGrid__rows--hidden">
        <table className="table" style={{ width: '100%', minWidth: `${props.width}px` }}>
          <tbody>
            {React.Children.map(
              props.children.constructor === Array ? props.children.slice(props.end, props.end + 1) : null,
              element => element
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Rows.propTypes = {
  getWrapperRef: PropTypes.func,
  height: PropTypes.number,
  fixedColumn: PropTypes.bool,
  getContainerRef: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.number,
  getTableRef: PropTypes.func,
  getTBodyRef: PropTypes.func,
  columnGroup: PropTypes.shape({}),
  disabledEditOverlayStyle: PropTypes.shape({}),
  topFill: PropTypes.number,
  bottomFill: PropTypes.number,
  start: PropTypes.number,
  end: PropTypes.number,
  children: PropTypes.node,
  columnsNo: PropTypes.number,
  showEmptyState: PropTypes.bool,
};
export default Rows;
