import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sortBy, deburr } from 'lodash';
import TopBarSingleChoice from './TopBarSingleChoice.jsx';
import Icon from '../common/Icon/Icon.jsx';

class TopBarLocationFilter extends Component {
  constructor(props) {
    super(props);

    this.changeFunc = this.changeFunc.bind(this);
  }

  changeFunc(location) {
    this.props.changeSelectedLocation(location);
  }

  render() {
    const sortedLocations = sortBy([...this.props.userLocations], location => deburr(location.name.toLowerCase()));

    return (
      <div className="k-topBarLocationFilter">
        <TopBarSingleChoice
          objectsToChoose={sortedLocations}
          selected={this.props.selectedLocation}
          onChange={this.changeFunc}
          icon={<Icon className="k-topBarChoice__selectedIcon" type="kadro" name="pin_sharp_circle" />}
          displayKey="name"
          className="k-topBarChoice--locationFilter"
        />
      </div>
    );
  }
}

TopBarLocationFilter.propTypes = {
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  locationFilter: PropTypes.shape({
    selectedLocation: PropTypes.shape({}),
  }),
  userPermissions: PropTypes.shape({
    isEmployee: PropTypes.bool,
  }),
  hideFilter: PropTypes.bool,
  changeLocationFilter: PropTypes.func,
};

export default TopBarLocationFilter;
