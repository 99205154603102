import {
  SHOW_SHADOW,
  HIDE_SHADOW,
} from '../constants/ActionTypes.js';

const initialState = {
  showShadow: false,
};

const uiState = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SHADOW:
      return Object.assign({}, state, {
        showShadow: true,
      });
    case HIDE_SHADOW:
      return Object.assign({}, state, {
        showShadow: false,
      });
    default:
      return state;
  }
};

export default uiState;
