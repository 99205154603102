/* eslint class-methods-use-this:0 */
import axios from 'axios';
import housecall from 'housecall';

import store from '../store.js';
import { uuid4 } from '../utils/baseHelpers.js';
import { increaseLoaderCounter, decreaseLoaderCounter } from '../actions/uiState.js';
// http://localhost:8888/proxy/

const emptyPromise = payload =>
  new Promise(resolve => {
    if (payload && payload.id) {
      resolve({ data: payload });
    } else {
      resolve({ data: Object.assign({}, payload, { id: uuid4() }) });
    }
  });

const housecallData = {
  concurrency: process.env.NODE_ENV === 'development' ? 100 : 5,
  cooldown: process.env.NODE_ENV === 'development' ? 0 : 200,
};

class KadroBaseConnections {
  constructor(connections, authentication) {
    this.base_url = connections.core;
    this.workerAddress = connections.workers;
    this.authentication = authentication;
    this.auth_token = '';
    this.queue = housecall(housecallData);
    this.counter = {
      regular: 0,
      blocking: 0,
      init: 0,
    };
    this.conn = axios.create({
      timeout: 300 * 1000,
    });
    this.conn.interceptors.response.use(
      response => response,
      error => {
        const { status } = error.response;
        if (status === 401 && this.authorizationErrorHandler) {
          this.authorizationErrorHandler(error, store.dispatch);
        }
        return Promise.reject(error);
      },
    );
  }

  /**
   * @param String token
   */
  setAuthToken(token) {
    this.authentication = token;
    if (token && token.indexOf('Bearer ') === 0) {
      this.conn.defaults.headers.common.Authorization = token;
    } else if (token) {
      this.conn.defaults.headers.common.Authorization = `AUTH-TOKEN token="${token}"`;
    } else {
      delete this.conn.defaults.headers.common.Authorization;
    }
  }

  setDemoMode(isDemo) {
    this.demoMode = isDemo;
  }

  getJSON(url, type = 'regular', payload = {}) {
    if (this.demoMode) return emptyPromise();

    store.dispatch(increaseLoaderCounter(type));
    return new Promise((resolve, reject) => {
      this.queue.push(() =>
        this.conn
          .get(url, payload, { type })
          .then(response => {
            store.dispatch(decreaseLoaderCounter(type));
            resolve(response);
          })
          .catch(err => {
            store.dispatch(decreaseLoaderCounter(type));
            reject(err);
          }),
      );
    });
  }

  postJSON(url, payload, type = 'regular') {
    if (this.demoMode) return emptyPromise(payload);

    store.dispatch(increaseLoaderCounter(type));
    return new Promise((resolve, reject) => {
      this.queue.push(() =>
        this.conn
          .post(url, payload, { type })
          .then(response => {
            store.dispatch(decreaseLoaderCounter(type));
            resolve(response);
          })
          .catch(err => {
            store.dispatch(decreaseLoaderCounter(type));
            reject(err);
          }),
      );
    });
  }

  putJSON(url, payload, type = 'regular') {
    if (this.demoMode) return emptyPromise(payload);

    store.dispatch(increaseLoaderCounter(type));
    return new Promise((resolve, reject) => {
      this.queue.push(() =>
        this.conn
          .put(url, payload, { type })
          .then(response => {
            store.dispatch(decreaseLoaderCounter(type));
            resolve(response);
          })
          .catch(err => {
            store.dispatch(decreaseLoaderCounter(type));
            reject(err);
          }),
      );
    });
  }

  deleteJSON(url, payload, type = 'regular') {
    if (this.demoMode) return emptyPromise(payload);

    store.dispatch(increaseLoaderCounter(type));
    return new Promise((resolve, reject) => {
      this.queue.push(() =>
        this.conn
          .delete(url, payload, { type })
          .then(response => {
            store.dispatch(decreaseLoaderCounter(type));
            resolve(response);
          })
          .catch(err => {
            store.dispatch(decreaseLoaderCounter(type));
            reject(err);
          }),
      );
    });
  }
  all(arr) {
    if (this.demoMode) return emptyPromise();
    return axios.all(arr);
  }

  setAuthorizationErrorHandler(errorHandler) {
    this.authorizationErrorHandler = errorHandler;
  }
}

export default KadroBaseConnections;
