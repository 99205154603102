import { defineMessages } from 'react-intl';

const dateModeIntl = defineMessages({
  week: {
    id: 'common.dateMode.week',
    defaultMessage: 'Tydzień',
  },
  month: {
    id: 'common.dateMode.month',
    defaultMessage: 'Miesiąc',
  },
});

export const dateModes = [
  {
    id: 0,
    length: 7, // Used to check if we switch from longer to shorter or the other way around
    type: 'week',
    displayText: dateModeIntl.week,
  },
  {
    id: 1,
    length: 30,
    type: 'month',
    displayText: dateModeIntl.month,
  },
];

export const getDateModes = intl =>
  dateModes.map(mode => ({
    ...mode,
    displayText: intl.formatMessage(mode.displayText, {}),
  }));
