import React from 'react';
import { PropTypes } from 'prop-types';

const LeftHeader = props => (
  <div
    className={`kadroGrid__leftHeader ${props.className || ''}`}
    style={{ ...props.style, ...props.extraStyle }}
    ref={props.setRef}
  >
    <table>
      <thead>
        {props.showGraph && (
          <tr className="empty">
            <th />
          </tr>
        )}
        <tr>{props.children}</tr>
      </thead>
    </table>
  </div>
);
LeftHeader.propTypes = {
  style: PropTypes.shape({}),
  extraStyle: PropTypes.shape({}),
  className: PropTypes.string,
  setRef: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  showGraph: PropTypes.bool,
};
export default LeftHeader;
