import { updateIntl } from 'react-intl-redux';

export const updateIntlMessages = messages => (dispatch, getState) => {
  const lang = getState().intl.locale;
  dispatch(
    updateIntl({
      locale: lang === 'pl' ? 'en' : 'pl',
      messages,
    }),
  );
};
