import { CHANGE_DATE_MODE } from '../constants/ActionTypes.js'

const dateMode = (state = 'week', action) => {
  switch (action.type) {
    case CHANGE_DATE_MODE:
      return action.payload
    default:
      return state
  }
}

export default dateMode