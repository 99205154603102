import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const TopBar = props => {
  const topBarClassnames = classnames('k-topBar', props.className);
  const topLeftClassnames = classnames('k-topBar__leftElements', {
    'k-topBar__leftElements--full': !(props.rightElements && props.rightElements.length),
  });
  return (
    <div>
      <div className={topBarClassnames}>
        {props.leftElements && props.leftElements.length ? (
          <div className={topLeftClassnames}>{props.leftElements}</div>
        ) : null}
        {props.rightElements && props.rightElements.length ? (
          <div className="k-topBar__rightElements">{props.rightElements}</div>
        ) : null}
      </div>
    </div>
  );
};

TopBar.propTypes = {
  leftElements: PropTypes.arrayOf(PropTypes.shape({})),
  rightElements: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

export default TopBar;
