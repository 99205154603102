import { FETCH_USER_LOCATIONS_SUCCESSFUL, CHANGE_SELECTED_LOCATION } from '../constants/ActionTypes';
import { conn } from '../actions/index.js';

export const fetchUserLocations = () => async dispatch => {
  const { data } = await conn.fetchUserLocations()

  dispatch({
    type: FETCH_USER_LOCATIONS_SUCCESSFUL,
    payload: data,
  })
  dispatch({
    type: CHANGE_SELECTED_LOCATION,
    payload: data[0],
  })
}