import { CHANGE_SELECTED_LOCATION } from '../constants/ActionTypes.js'

const initialState = {
  selectedLocation: {},
}

const selectedLocation = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SELECTED_LOCATION:
      return { selectedLocation: action.payload }
    default:
      return state
  }
}

export default selectedLocation