import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import MDTextInput from '../MDTextInput/MDTextInput.jsx';

class MDPasswordInput extends Component {
  constructor(props) {
    super(props);
    this.onClickShowPassword = this.onClickShowPassword.bind(this);
    this.getEyeIcon = this.getEyeIcon.bind(this);
    this.state = { showPassword: false };
  }

  onClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword,
    }));
  }

  getEyeIcon() {
    return this.state.showPassword ? 'k-icon-visibility_off' : 'k-icon-visibility';
  }

  render() {
    return (
      <MDTextInput
        {...this.props}
        type={this.state.showPassword ? 'text' : 'password'}
        onIconClick={() => this.onClickShowPassword()}
        icon={this.getEyeIcon()}
        iconStyle={{ fontSize: '24px' }}
      />
    );
  }
}

MDPasswordInput.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape({}),
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onIconClick: PropTypes.func,
};

export default MDPasswordInput;
