import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../Button/Button.jsx';

const REFRESH_TIMEOUT = 15 * 1000;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV === 'development') return;
    setTimeout(() => {
      window.location.reload(true);
    }, REFRESH_TIMEOUT);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="k-errorBoundary">
          <p className="k-errorBoundary__mainText">
            <FormattedMessage id="common.errorBoundary.apologyText" defaultMessage="Wystąpił błąd." />
          </p>
          <p className="k-errorBoundary__subText">
            <FormattedMessage
              id="common.errorBoundary.subText"
              defaultMessage="Strona odświeży się za 15 sekund lub możesz to zrobić teraz klikając przycisk poniżej."
            />
          </p>
          <p className="k-errorBoundary__contactText">
            <FormattedMessage
              id="common.errorBoundary.contactText"
              defaultMessage="Poinformuj nas, jeżeli błąd nie zniknie."
            />
          </p>
          <p className="k-errorBoundary__email">
            <FormattedMessage
              id="common.errorBoundary.email"
              defaultMessage="E-mail: {mail}"
              values={{
                mail: <a href="mailto:kontakt@kadromierz.pl">kontakt@kadromierz.pl</a>,
              }}
            />
          </p>
          <p className="k-errorBoundary__phone">
            <FormattedMessage id="common.errorBoundary.phone" defaultMessage="Telefon: +48 732 021 521" />
          </p>
          <div className="k-errorBoundary__refresh">
            <Button onClick={() => window.location.reload(true)} modifiers="blue high">
              <FormattedMessage
                id="common.errorBoundary.refreshText"
                defaultMessage="Kliknij tutaj aby odświeżyć stronę"
              />
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
