import {connect} from 'react-redux'
import {changeSelectedLocation} from '../../actions/topBar.js'
import {fetchScheduleForLocation} from '../../actions/shifts.js'
import {logoutUser} from '../../actions/auth.jsx'
import {changeDateMode} from '../../actions/dateMode.js'
import BasicSchedule from './BasicSchedule.jsx'

const mapStateToProps = ({reducer}) => ({
  selectedLocation: reducer.topBar.selectedLocation,
  userLocations: reducer.userLocations,
  shifts: reducer.shifts,
  dateMode: reducer.dateMode,
})

const mapDispatchToProps = {
  changeSelectedLocation,
  fetchScheduleForLocation,
  logoutUser,
  changeDateMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicSchedule)