import { connect } from 'react-redux';
import { requestAuthenticateUser, requestAuthenticateUserFromCode } from '../actions/auth.jsx';
import LoginView from '../components/LoginPage/LoginPage.jsx';

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  authUser: requestAuthenticateUser,
  requestAuthenticateUserFromCode,
};

const MainViewLogin = connect(mapStateToProps, mapDispatchToProps)(LoginView);

export default MainViewLogin;
