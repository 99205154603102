import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const MDTextInput = props => {
  let modifiers = props.modifiers || [];
  if (!Array.isArray(modifiers)) {
    modifiers = modifiers.split(' ');
  }
  const className = classNames(
    'kmd-textInput',
    props.className,
    ...modifiers.map(modifier => `kmd-textInput--${modifier}`),
    {
      'kmd-textInput--valid': props.value && !props.errorMessage,
      'kmd-textInput--error': props.errorMessage,
    }
  );

  const iconClassname = classNames('k-icon', props.icon, { 'k-icon--clicky': !!props.onIconClick });

  return (
    <div className={className}>
      <input
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        style={props.style}
        id={props.id}
        name={props.id}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
      />
      <label>{props.label}</label>
      <button
        type="button"
        onClick={e => {
          e.preventDefault();
          if (props.onIconClick) props.onIconClick();
        }}
        className="kmd-textInput__icon"
        tabIndex={-1}
      >
        <i className={iconClassname} style={props.iconStyle} />
      </button>
      <span className="kmd-textInput__bar" />
      <div className="kmd-textInput__error">{props.errorMessage}</div>
    </div>
  );
};

MDTextInput.defaultProps = {
  type: 'text',
  style: {},
  id: '',
  value: '',
};

MDTextInput.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape({}),
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onIconClick: PropTypes.func,
};

export default MDTextInput;
