import { connect } from 'react-redux';
import AuthenticatedApp from '../components/AuthenticatedApp.jsx';
import { updateIntlMessages } from '../actions/intl.js';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
  currentCompany: state.reducer.currentCompany,
  userPermissions: state.reducer.userPermissions,
  deviceInfo: state.reducer.deviceInfo,
});

const mapDispatchToProps = {
  updateIntlMessages,
};

const AuthenticatedAppContainer = connect(mapStateToProps, mapDispatchToProps)(AuthenticatedApp);

export default AuthenticatedAppContainer;
