import PropTypes from 'prop-types';
import React from 'react';

const ShadowLoader = props => {
  if ((!props.showShadow && !props.showLoading) || props.isInit) {
    return <div />;
  }
  let loader = '';
  if (props.showLoading) {
    loader = (
      <div className="k-loader">
        <img className="k-loader__tick" src="/img/load2.png" alt="logo" />
      </div>
    );
  }
  return <div className="modal-backdrop allblock in">{loader}</div>;
};

ShadowLoader.propTypes = {
  showLoading: PropTypes.bool.isRequired,
  showShadow: PropTypes.bool,
  isInit: PropTypes.bool,
};

export default ShadowLoader;
