import React from 'react'
import moment from 'moment'

export const getHeaders = (numberOfDays = 7) => {
  return Array.from({length: numberOfDays}, (item, index) => (
    <th>
      {moment().add(index, 'days').format('dddd, DD.MM.YYYY')}
    </th>
  ))
}

export const getCells = (shifts, numberOfDays = 7) => {
  const datesArray = Array.from({length: numberOfDays}, (item, index) => moment().add(index, 'days'))
  
  return datesArray.reduce((result, date) => {
    const relevantShifts = shifts.filter(shift => moment(shift.date).isSame(date, 'day')).sort((a, b) => {
      const hoursA = a.working_hours.split(':')[0]
      const hoursB = b.working_hours.split(':')[0]
      
      return hoursA - hoursB
    })

    return [
      ...result,
      (
        <td className="middle center">
          {relevantShifts.map(shift => <div>{shift.working_hours}</div>)}
        </td>
      )
      ]
  }, [])
}