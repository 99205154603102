import { FETCH_SHIFTS_SUCCESSFUL, CHANGE_SELECTED_LOCATION, CHANGE_DATE_MODE } from '../constants/ActionTypes.js'

const shifts = (state = [], action) => {
  switch (action.type) {
    case FETCH_SHIFTS_SUCCESSFUL:
      return action.payload
    case CHANGE_SELECTED_LOCATION:
      return []
    case CHANGE_DATE_MODE:
      return []
    default:
      return state
  }
}

export default shifts