import { UPDATE_PERMISSIONS_SUCCESSFUL } from '../constants/ActionTypes.js';

import { conn, connectionError } from './index.js';
import { logoutUser } from './auth.jsx';

export const updateUserPermissionsSuccess = change => ({
  type: UPDATE_PERMISSIONS_SUCCESSFUL,
  payload: change,
});

export const updatePermissionsRequest = (aditionalPermissions = {}, requestType) => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .getUserStatus(requestType)
      .then(response => {
        const { data } = response;
        const userData = { ...data, permissions: data.permissions };
        dispatch(updateUserPermissionsSuccess({ ...userData, ...aditionalPermissions }));
        resolve(data);
      })
      .catch(err => {
        dispatch(connectionError(err));
        dispatch(logoutUser());
        reject(err);
      });
  });

export const updatePermissions = (aditionalPermissions = {}, requestType) => 
  dispatch => dispatch(updatePermissionsRequest(aditionalPermissions, requestType));