import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TopBarSearchInput from './TopBarSearchInput.jsx';

class TopBarSingleChoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchPhrase: '',
      isFocused: false,
    };

    this.setFocus = this.setFocus.bind(this);
    this.searchFilterChange = this.searchFilterChange.bind(this);
    this.blurHandler = this.blurHandler.bind(this);
    this.changeFunc = this.changeFunc.bind(this);
    this.displayText = this.displayText.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.blurHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.blurHandler);
  }

  setFocus() {
    this.setState(
      prev => ({
        isFocused: !prev.isFocused,
      }),
      () => {
        if (this.state.isFocused && this.searchInput) this.searchInput.setFocus();
      }
    );
  }

  searchFilterChange(e) {
    this.setState({ searchPhrase: e.target.value });
  }

  blurHandler(e) {
    if (this.singleChoiceRef && !this.singleChoiceRef.contains(e.target)) {
      this.setState({
        isFocused: false,
      });
    }
  }

  changeFunc(object) {
    this.props.onChange(object);
  }

  displayText(object) {
    return object[this.props.displayKey];
  }

  render() {
    const className = classnames('k-topBarFilter k-topBarChoice', this.props.className, {
      'k-topBarChoice--focused': this.state.isFocused,
      'k-topBarChoice--short': this.props.icon,
    });

    let objectsToRender = this.props.objectsToChoose;
    if (this.props.addAllOption) {
      objectsToRender = [{ id: 'all', name: 'Wszystkie' }, ...objectsToRender];
    }

    objectsToRender = objectsToRender.filter(
      object =>
        object.id !== this.props.selected.id &&
        ~object[this.props.displayKey].toLowerCase().indexOf(this.state.searchPhrase.toLowerCase())
    );
    objectsToRender = [this.props.selected, ...objectsToRender];

    return (
      <div
        className={className}
        ref={el => {
          this.singleChoiceRef = el;
        }}
      >
        <div className="k-topBarChoice__selected" onClick={this.setFocus} role="presentation">
          <div className="k-topBarChoice__selectedContent">
            <span>{this.props.icon}</span>
            <span className="k-topBarChoice__selectedText">{this.displayText(this.props.selected)}</span>
            <span className="k-topBarChoice__arrowDown" />
          </div>
        </div>
        <div
          className="k-topBarChoice__list"
          onBlur={this.blurHandler}
          tabIndex={0}
          role="option"
          aria-selected={false}
        >
          {!this.props.hideSearch ? (
            <TopBarSearchInput
              onRef={ref => {
                this.searchInput = ref;
              }}
              searchPhrase={this.state.searchPhrase}
              onChange={this.searchFilterChange}
            />
          ) : null}
          {objectsToRender.map(object => {
            const elementClassName = classnames('k-topBarChoice__element', object.class_name, {
              'k-topBarChoice__element--paddingRight': object.elements,
              'k-topBarChoice__element--singleSelect': object.id === this.props.selected.id,
            });
            return (
              <button key={object.id} className={elementClassName} onMouseDown={() => this.changeFunc(object)}>
                {this.displayText(object)}
                {object.elements}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

TopBarSingleChoice.propTypes = {
  displayKey: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  objectsToChoose: PropTypes.arrayOf(PropTypes.shape()),
  selected: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  hideSearch: PropTypes.bool,
  addAllOption: PropTypes.bool,
  onChange: PropTypes.func,
};

export default TopBarSingleChoice;
