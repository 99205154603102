import React from 'react';

import UnauthenticatedLayout from '../UnauthenticatedLayout/UnauthenticatedLayout.jsx';
import UnauthenticatedLayoutShowcase from '../UnauthenticatedLayoutShowcase/UnauthenticatedLayoutShowcase.redux.js';

const FullScreenLoader = () => (
  <UnauthenticatedLayout showcase={<UnauthenticatedLayoutShowcase />}>
    <div className="k-loader k-loader--unauthenticated">
      <img className="k-loader__tick" src="/img/load2.png" alt="logo" />
    </div>
  </UnauthenticatedLayout>
);

export default FullScreenLoader;
