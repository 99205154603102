import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import TranslatorHelper from './common/TranslatorHelper.jsx';
import ErrorBoundary from './common/ErrorBoundary/ErrorBoundary.jsx';

class AuthenticatedApp extends Component {
  componentDidMount() {
    if (
      !['Chrome', 'Firefox', 'Safari'].includes(
        this.props.deviceInfo.userBrowser.name,
      ) &&
      !this.props.deviceInfo.isMobile
    ) {
      /* eslint-disable max-len */
      this.props.showNotificationBar(
        <FormattedMessage
          id="app.browserWarning"
          defaultMessage="Zauważyliśmy że twoja przeglądarka to {browser}, niestety nie wspieramy jej i podczas używania aplikacji mogą wystąpić błędy.\\n Zachęcamy do korzystania z Chrome, Firefox lub Safari w najnowszych wersjach."
          values={{
            browser: this.props.deviceInfo.userBrowser.name,
          }}
        />,
        true,
      );
      /* eslint-enable max-len */
    }
  }

  render() {
    return (
      <div>
        <div className="k-app">
          <div className="k-app__wrapper">
            <div>
              <ErrorBoundary>{this.props.children}</ErrorBoundary>
            </div>
          </div>
          {process.env.ENABLE_TRANSLATION_HELPER ? (
            <TranslatorHelper
              updateIntlMessages={this.props.updateIntlMessages}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

AuthenticatedApp.propTypes = {
  deviceInfo: PropTypes.shape({
    isMobile: PropTypes.bool,
    userBrowser: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
  showNotificationBar: PropTypes.func,
  updateIntlMessages: PropTypes.func,
};

export default AuthenticatedApp;
