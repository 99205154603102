import DetectRTC from 'detectrtc';

import { UPDATE_WINDOW_SIZE, GET_USER_MEDIA } from '../constants/ActionTypes.js';

const initialState = {
  windowSize: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  supportsNotifications: !!(window.Notification || navigator.mozNotification) /* Firefox for Android and Firefox OS */,
  supportsWebWorkers: !!window.Worker,
  userOS: {
    name: DetectRTC.osName,
    version: DetectRTC.osVersion,
  },
  userBrowser: {
    name: DetectRTC.browser.name,
    version: DetectRTC.browser.fullVersion,
    isPrivateBrowsing: DetectRTC.browser.isPrivateBrowsing,
  },
  userMedia: {
    hasCamera: false,
    hasSpeakers: false,
    hasMicrophone: false,
  },
  isMobileView: window.innerWidth < 900,
  isMobile: DetectRTC.isMobileDevice,
};

const deviceInfo = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_WINDOW_SIZE:
      return {
        ...state,
        windowSize: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        isMobileView: window.innerWidth < 900,
      };
    case GET_USER_MEDIA:
      return {
        ...state,
        userMedia: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
export default deviceInfo;
