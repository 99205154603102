import { FETCH_USER_LOCATIONS_SUCCESSFUL } from '../constants/ActionTypes.js'

const userLocations = (state = [], actions) => {
  switch (actions.type) {
    case FETCH_USER_LOCATIONS_SUCCESSFUL:
      return actions.payload
    default:
      return state
  }
}

export default userLocations