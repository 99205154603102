import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import TopBarLocationFilter from '../TopBar/TopBarLocationFilter.jsx'
import TopBar from '../TopBar/TopBar.jsx'
import Button from '../common/Button/Button.jsx'
import { FormattedMessage } from 'react-intl'
import BasicScheduleTable from './BasicScheduleTable.jsx'
import TopBarDateMode from '../TopBar/TopBarDateMode.jsx'

const BasicSchedule = ({
  userLocations,
  selectedLocation,
  changeSelectedLocation,
  fetchScheduleForLocation,
  shifts,
  logoutUser,
  changeDateMode,
  dateMode
}) => {
  return (
    <div>
      <TopBar
        leftElements={[
        <TopBarLocationFilter 
          key="locationFilter" 
          userLocations={userLocations}
          selectedLocation={selectedLocation}
          changeSelectedLocation={changeSelectedLocation}
        />,
        <TopBarDateMode 
          modes={[0, 1]} 
          mainDateChangeMode={changeDateMode}
          dateMode={dateMode}
        />,
        <Button 
          modifiers={['orange', 'small', 'radius', 'margin-left']}
          onClick={() => fetchScheduleForLocation(selectedLocation.id)}
          disabled={!selectedLocation.id}
        >
          <FormattedMessage id="load" defaultMessage="Wczytaj" />
        </Button>
      
      ]}
        rightElements={[
          <div key="DashboardDate" className="dashboard__date">
            {moment().format('dddd, DD MMMM YYYY')}
          </div>,
          <Button
            onClick={logoutUser}
            modifiers={['reverse-blue', 'small', 'margin-left']}
          >
            <FormattedMessage id="logout" defaultMessage="Wyloguj" />
          </Button>
        ]}
      />
      <BasicScheduleTable 
        shifts={shifts}
        dateMode={dateMode}
      />
    </div>
  )
}

BasicSchedule.propTypes = {
  userLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  selectedLocation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  changeSelectedLocation: PropTypes.func,
  fetchScheduleForLocation: PropTypes.func,
  shifts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    working_hours: PropTypes.string,
    date: PropTypes.string,
  })),
  logoutUser: PropTypes.func,
  changeDateMode: PropTypes.func,
  dateMode: PropTypes.string,
}

export default BasicSchedule
