import { connect } from 'react-redux';
import Component from './ShadowLoader.jsx';

const mapStateToProps = state => ({
  showShadow: state.reducer.uiState.showShadow,
  showLoading: state.reducer.loaderState.blocking > 0,
  isInit: state.reducer.loaderState.init > 0,
});

const mapDispatchToProps = {};

const ShadowLoaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ShadowLoaderContainer;
