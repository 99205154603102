import React from 'react';
import PropTypes from 'prop-types';

import MainViewLogin from '../containers/MainViewLogin.js';
import FullScreenLoader from '../components/misc/FullScreenLoader.jsx';
import ShadowLoader from './misc/ShadowLoader/ShadowLoader.redux';
import AuthenticatedApp from '../containers/AuthenticatedAppContainer.js';

export const MainApp = (props) => {
  let viewToRender = <MainViewLogin />;
  if (props.showFullScreenLoader) {
    viewToRender = <FullScreenLoader />;
  } else if (props.currentUser && props.currentUser.authenticated) {
    if (props.currentUser.disabled) {
      viewToRender = <div />;
    } else {
      viewToRender = <AuthenticatedApp>{props.children}</AuthenticatedApp>;
    }
  }
  return (
    <div>
      <ShadowLoader />
      {viewToRender}
    </div>
  );
};

MainApp.propTypes = {
  currentUser: PropTypes.shape({
    authenticated: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  children: PropTypes.node,
  showFullScreenLoader: PropTypes.bool,
};

export default MainApp;
