import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Icon = props => {
  if (!['material', 'material-outlined', 'kadro'].includes(props.type)) throw new Error('Unsupported Icon type');

  switch (props.type) {
    case 'material': {
      const className = classNames('material-icons', props.className);

      return <i className={className}>{props.name}</i>;
    }
    case 'material-outlined': {
      const className = classNames('material-icons-outlined', props.className);

      return <i className={className}>{props.name}</i>;
    }
    case 'kadro': {
      const className = classNames('k-icon', `k-icon-${props.name}`, props.className);

      return <i className={className} />;
    }
    default:
      return <i />;
  }
};

Icon.defaultProps = {
  type: 'material',
};

Icon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default Icon;
