import * as actionTypes from '../constants/ActionTypes.js';

export const increaseLoaderCounter = type => ({
  type: actionTypes.INCREASE_LOADER_COUNTER,
  payload: type,
});

export const decreaseLoaderCounter = type => ({
  type: actionTypes.DECREASE_LOADER_COUNTER,
  payload: type,
});

export const showShadow = () => ({
  type: actionTypes.SHOW_SHADOW,
});

export const hideShadow = () => ({
  type: actionTypes.HIDE_SHADOW,
});
