import React from 'react';
import { PropTypes } from 'prop-types';
import { Router, Route, IndexRoute } from 'react-router';

import MainAppView from '../containers/MainApp.js';
import PermissionWrapper from '../containers/PermissionWrapper.js';
import BasicSchedule from '../components/BasicSchedule/BasicSchedule.redux.js';

export const Routes = (props) => (
  <Router history={props.history}>
    <Route path="/" component={MainAppView}>
      <IndexRoute
        component={PermissionWrapper}
        employee={BasicSchedule}
        manager={BasicSchedule}
      />
    </Route>
  </Router>
);

Routes.propTypes = {
  history: PropTypes.shape({}),
};

export default Routes;
