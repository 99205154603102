import React from 'react';
import { PropTypes } from 'prop-types';

const Header = props => (
  <div
    className={`kadroGrid__header ${props.className || ''}`}
    ref={props.headerRef}
    style={{
      borderLeft: props.fixedColumn ? 'none' : '1px solid #ddd',
    }}
  >
    <table style={{ minWidth: `${props.width}px` }}>
      {props.columnGroup ? props.columnGroup : null}
      {props.children}
    </table>
  </div>
);
Header.propTypes = {
  fixedColumn: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  columnGroup: PropTypes.shape({}),
  headerRef: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
};
export default Header;
