import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { intlReducer } from 'react-intl-redux';
import { routerReducer } from 'react-router-redux';
import { getInitialIntl } from './utils/intlHelpers.jsx';
import reducer from './reducers';

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist: ['_LOADER_COUNTER'],
      })
    : compose;
/* eslint-enable */

const store = createStore(
  combineReducers({
    reducer,
    intl: intlReducer,
    routing: routerReducer,
  }),
  getInitialIntl(),
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware.withExtraArgument(getInitialIntl().intl),
    )
  )
);

export default store;
