import KadroConnections from '../containers/MainConnections.js';
import * as actionTypes from '../constants/ActionTypes.js';

export const connections = {
  master: {
    core: 'https://api.kadromierz.pl',
    workers: 'https://api.kadromierz.pl/workers',
    socket: 'https://sockets.kadromierz.pl',
  },
  alfa: {
    core: 'https://api.kadromierz.pl',
    workers: 'https://api.kadromierz.pl/workers',
    socket: 'https://sockets.kadromierz.pl',
  },
  staging: {
    core: 'https://stagingapi.kadromierz.pl/api',
    workers: 'https://stagingapi.kadromierz.pl/workers',
    socket: 'https://staging-sockets.kadro.dev',
  },
  'local-api-dev': {
    core: 'http://kadromierz.test',
    workers: 'http://localhost:3000/workers',
    socket: 'https://sockets.kadromierz.pl',
  },
  local: {
    core: 'https://app.kadromierz.pl',
    workers: 'http://localhost:3000/workers',
    socket: 'https://sockets.kadromierz.pl',
  },
  pattern: {
    core: '{{TARGET_CORE}}',
    workers: '{{TARGET_WORKERS}}',
    socket: '{{TARGET_SOCKET}}',
  },
};

export const conn = new KadroConnections(connections.master);

export const showBlockingLoader = forWhat => ({
  type: actionTypes.SHOW_BLOCKING_LOADER,
  payload: forWhat,
});

export const connectionError = err => {
  console.error(err);
  return {
    type: actionTypes.CONNECTION_ERROR,
    payload: err,
  };
};
