import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getDateModes } from '../../constants/dateModes.js';

class TopBarDateMode extends PureComponent {
  constructor(props, context) {
    super(props, context);
    const objectsToChoose = getDateModes(context.intl).filter(mode => props.modes.includes(mode.id));
    this.state = {
      objectsToChoose,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(mode) {
    this.props.mainDateChangeMode(mode.type);
  }

  render() {
    if (this.props.hideFilter) {
      return null;
    }
    const selectedObject =
      this.state.objectsToChoose.find(mode => mode.type === this.props.dateMode) ||
      this.state.objectsToChoose[0];
    const className = classnames('k-topBarFilter k-topBarDateMode', {
      'k-topBarDateMode__disabled': this.props.disabled,
    });
    return (
      <div className={className}>
        {this.state.objectsToChoose.map(object => {
          const modeClassnames = classnames('k-topBarDateMode__mode', {
            'k-topBarDateMode__mode--selected': object.id === selectedObject.id,
          });
          return (
            <button className={modeClassnames} key={object.id} onClick={() => this.onChange(object)}>
              <span className="k-topBarDateMode__modeText">{object.displayText}</span>
              <span className="k-topBarDateMode__modeLength">
                {object.id === 2 ? <i className="material-icons">calendar_today</i> : object.length}
              </span>
            </button>
          );
        })}
      </div>
    );
  }
}

TopBarDateMode.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

TopBarDateMode.propTypes = {
  modes: PropTypes.arrayOf(PropTypes.number).isRequired,
  disabled: PropTypes.bool,
  hideFilter: PropTypes.bool,
  mainDateStore: PropTypes.shape({
    dateMode: PropTypes.string,
  }),
  mainDateChangeMode: PropTypes.func,
};

export default TopBarDateMode;
