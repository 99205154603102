export const REQUEST_AUTHENTICATE_USER = 'REQUEST_AUTHENTICATE_USER';
export const REQUEST_USEREMPLOYEES_DATA = 'REQUEST_USEREMPLOYEES_DATA';
export const AUTH_USER_SUCCESSFUL = 'AUTH_USER_SUCCESSFUL';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';
export const AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT';
export const AUTH_USER_LOGOUT_ERROR = 'AUTH_USER_LOGOUT_ERROR';
export const SET_AUTH_TOKEN_SUCCESS = 'SET_AUTH_TOKEN_SUCCESS';
export const NO_TOKEN_STORED = 'NO_TOKEN_STORED';
export const STORE_AUTH_TOKEN_SUCCESS = 'STORE_AUTH_TOKEN_SUCCESS';

export const SHOW_BLOCKING_LOADER = 'SHOW_BLOCKING_LOADER';
export const CONNECTION_ERROR = 'CONNECTION_ERROR';
export const SHOW_SHADOW = 'SHOW_SHADOW';
export const HIDE_SHADOW = 'HIDE_SHADOW';
export const INCREASE_LOADER_COUNTER = 'INCREASE_LOADER_COUNTER';
export const DECREASE_LOADER_COUNTER = 'DECREASE_LOADER_COUNTER';
export const GET_CURRENT_COMPANY_ERROR = 'GET_CURRENT_COMPANY_ERROR';
export const GET_CURRENT_COMPANY_SUCCESFUL = 'GET_CURRENT_COMPANY_SUCCESFUL';
export const GET_CURRENT_USER_SUCCESFUL = 'GET_CURRENT_USER_SUCCESFUL';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PROMO_CONTENT_SUCCESS = 'GET_PROMO_CONTENT_SUCCESS';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_USER_MEDIA = 'GET_USER_MEDIA';
export const REQUEST_CURRENT_COMPANY = 'REQUEST_CURRENT_COMPANY';
export const CHECK_PERMISSIONS_SUCCESS = 'CHECK_PERMISSIONS_SUCCESS';
export const UPDATE_CURRENT_USER_SUCCESFUL = 'UPDATE_CURRENT_USER_SUCCESFUL';
export const UPDATE_PERMISSIONS_SUCCESSFUL = 'UPDATE_PERMISSIONS_SUCCESSFUL';
export const UPDATE_WINDOW_SIZE = 'UPDATE_WINDOW_SIZE';

export const CHANGE_SELECTED_LOCATION = 'CHANGE_SELECTED_LOCATION';
export const FETCH_USER_LOCATIONS_SUCCESSFUL = 'FETCH_USER_LOCATIONS_SUCCESSFUL';
export const FETCH_SHIFTS_SUCCESSFUL = 'FETCH_SHIFTS_SUCCESSFUL';

export const CHANGE_DATE_MODE = 'CHANGE_DATE_MODE';