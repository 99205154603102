import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const EmptyState = props => {
  const { imgSrc, name, emptyStateHeader, emptyStateText, ctaText, ctaHandler, className, style, filerStyle } = props;
  const emptyStateClassNames = classnames('k-EmptyState__content', className);

  return (
    <div className={emptyStateClassNames} style={style}>
      <div className="k-EmptyState__contentContainer">
        {imgSrc ? (
          <div className="k-EmptyState__imageContainer">
            <img className="k-EmptyState__image" src={imgSrc} alt={name} />
          </div>
        ) : null}
        <div className="k-EmptyState__textContainer">
          <h2 className="k-EmptyState__header"> {emptyStateHeader || ''} </h2>
          <p>
            {emptyStateText || ''}
            <span className="k-EmptyState__link" role="presentation" onClick={ctaHandler}>
              {` ${ctaText || ''}`}
            </span>
          </p>
        </div>
      </div>
      <div className="k-EmptyState__filterBlock" style={filerStyle} />
    </div>
  );
};
EmptyState.defaultProps = {
  filerStyle: {},
  style: {},
};
EmptyState.propTypes = {
  imgSrc: PropTypes.string,
  emptyStateHeader: PropTypes.string,
  emptyStateText: PropTypes.string,
  name: PropTypes.string,
  ctaText: PropTypes.string,
  ctaHandler: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  filerStyle: PropTypes.shape({}),
};

export default EmptyState;
