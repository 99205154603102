import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-intl-redux';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import pl from 'react-intl/locale-data/pl';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import moment from 'moment';
import locale_pl from 'moment/locale/pl';
import './css/style.scss';
import './css/kadro.scss';
import store from './store.js';
import Routes from './components/Routes.jsx';
import {checkCookiesForTokens} from './actions/auth.jsx';

addLocaleData([...en, ...pl]);
moment.updateLocale('pl', locale_pl);

const history = syncHistoryWithStore(browserHistory, store);

store.dispatch(checkCookiesForTokens());

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Routes history={history} />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);