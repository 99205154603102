import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import UnauthenticatedLayout from '../UnauthenticatedLayout/UnauthenticatedLayout.jsx';
import UnauthenticatedLayoutShowcase from '../UnauthenticatedLayoutShowcase/UnauthenticatedLayoutShowcase.redux.js';
import Button from '../common/Button/Button.jsx';
import MDTextInput from '../common/MDTextInput/MDTextInput.jsx';
import MDPasswordInput from '../common/MDPasswordInput/MDPasswordInput.jsx';
import MDCheckbox from '../common/MDCheckbox/MDCheckbox.jsx';

const messages = defineMessages({
  email: {
    id: 'view.login.email',
    defaultMessage: 'E-mail',
  },
  password: {
    id: 'view.login.password',
    defaultMessage: 'Hasło',
  },
  rememberMe: {
    id: 'view.login.rememberMe',
    defaultMessage: 'Zapamiętaj mnie',
  },
});

class LoginView extends Component {
  static getAuthCodeFromHash(hash = window.location.hash) {
    const matched = hash.match(/auth-code=([^&]*)/);

    if (!matched) {
      return null;
    }

    const result = matched[1];

    if (!result) {
      return null;
    }

    return result;
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      email: '',
      password: '',
      rememberMe: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentWillMount() {
    const authCode = LoginView.getAuthCodeFromHash();
    if (authCode) {
      this.props.requestAuthenticateUserFromCode(authCode);
    }
  }

  handleInputChange(e) {
    const { target } = e;
    this.setState({
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    });
  }

  formSubmit(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { email, password, rememberMe } = this.state;
    this.props.authUser(email, password, rememberMe);
    this.setState({
      email: '',
      password: '',
      rememberMe: false,
    });
  }

  render() {
    return (
      <div>
        <UnauthenticatedLayout showcase={<UnauthenticatedLayoutShowcase />}>
          <div className="k-loginView__welcomeMessage">
            <FormattedMessage id="login.view.welcomeMessage" defaultMessage="Zaloguj się" />
          </div>
          <form className="k-loginView" onSubmit={this.formSubmit}>
            <div className="k-loginView__form">
              <MDTextInput
                id="email"
                value={this.state.email}
                icon="k-icon-profile"
                label={this.context.intl.formatMessage(messages.email, {})}
                onChange={this.handleInputChange}
              />
              <MDPasswordInput
                id="password"
                value={this.state.password}
                label={this.context.intl.formatMessage(messages.password, {})}
                onChange={this.handleInputChange}
              />
              <MDCheckbox
                id="rememberMe"
                value={this.state.rememberMe}
                text={this.context.intl.formatMessage(messages.rememberMe, {})}
                onChange={this.handleInputChange}
              />
              <div className="k-loginView__loginButton">
                <Button type="submit" modifiers="orange high uppercase">
                  <FormattedMessage id="login" defaultMessage="Zaloguj" />
                </Button>
              </div>
            </div>
          </form>
        </UnauthenticatedLayout>
      </div>
    );
  }
}

LoginView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

LoginView.propTypes = {
  authUser: PropTypes.func,
  requestAuthenticateUserFromCode: PropTypes.func,
};

export default LoginView;
