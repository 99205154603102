import { FETCH_SHIFTS_SUCCESSFUL } from '../constants/ActionTypes.js'
import { conn } from '../actions/index.js'
import { dateModes } from '../constants/dateModes.js'

export const fetchScheduleForLocation = (locationId) => async (dispatch, getState) => {
  const { dateMode } = getState().reducer
  const numberOfDays = dateModes.find(mode => mode.type === dateMode).length
  const { data } = await conn.fetchScheduleForLocation(locationId, numberOfDays)

  dispatch({
    type: FETCH_SHIFTS_SUCCESSFUL,
    payload: data,
  })
}