import { combineReducers } from 'redux';
import promoContents from './promoContents.js';
import currentUser from './currentUser.js';
import loaderState from './loaderState.js';
import uiState from './uiState.js';
import userPermissions from './userPermissions.js';
import deviceInfo from './deviceInfo.js';
import topBar from './topBar.js';
import userLocations from './userLocations.js';
import shifts from './shifts.js';
import dateMode from './dateMode.js';
import { AUTH_USER_LOGOUT } from '../constants/ActionTypes.js';

const app = combineReducers({
  promoContents,
  loaderState,
  currentUser,
  uiState,
  userPermissions,
  deviceInfo,
  topBar,
  userLocations,
  shifts,
  dateMode,
})

const rootReducer = (state, action) => {
  if (action.type === AUTH_USER_LOGOUT) {
    state = undefined;
  }
  return app(state, action);
};

export default rootReducer;
